<template>
    <div class="newsbox">
        <div class="newscon">
            <div class="newshead">
                <div class="txt"><i></i><span>{{ModuleName}}</span><div class="clear"></div></div>
                <div class="ntab">
                    <div class="ta" :class="newstypeactive==item.Id?'active':''" @click.prevent="newstypebtn(item.Id)" v-for="(item,index) in newstypedata" :key="index">{{item.CategoryName}}</div>
                    <div class="ta" :class="newstypeactive==0?'active':''" @click.prevent="newstypebtn(0)">本地（{{ProvinceName}}）</div>
                    <div class="clear"></div>
                </div>
                <div class="more" @click.prevent="$router.push('/newslist?type='+newstypeactive)">更多<i class="iconfont">&#xe600;</i></div>
                <div class="clear"></div>
            </div>
            <div class="clear"></div>
            <div class="newslist">
                <div class="abox" v-for="item in newsdata" :key="item.Id" @click.prevent="$router.push('/newsdetail?id='+item.Id)">
                    <div class="tbox">
                        <div class="tit">{{ item.NewsTitle }}</div>
                        <div class="mbox">
                            <span v-if="item.ExamProvinceName"><i class="iconfont">&#xe615;</i>{{item.ExamProvinceName}}</span>
                            <span><i class="iconfont">&#xe673;</i>{{item.Source}}</span>
                            <span><i class="iconfont">&#xe665;</i>{{item.ReleaseDate.substring(0,16)}}</span>
                            <span><i class="iconfont">&#xe650;</i>{{item.ViewCountBase+item.ViewCountReal}} 次阅读</span>
                        </div>
                    </div>
                    <div class="nimg">
                        <div class="img" :style="{background:'url('+item.CoverImageFileUrl+') no-repeat center center / cover'}"></div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="pages" v-if="newsdata.length==0">
                    <i class="iconfont">&#xe67c;</i>
                    <span>{{pagetxt}}</span>
                </div>
            </div>
            <!-- 热门资讯 -->
            <div class="newshot">
                <div class="rtit"><i></i><span>热门资讯</span><div class="clear"></div></div>
                <div class="rlist">
                    <div class="ra" v-for="(item,index) in newshotdata" :key="index" @click.prevent="$router.push('/newsdetail?id='+item.Id)">
                        <div class="ico"><i class="iconfont" v-if="index<3">&#xe64e;</i><span>{{index+1}}</span></div>
                        <div class="txt nowrap">{{ item.NewsTitle }}</div>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <div class="clear"></div>
    </div>
</template>
<script>
import API from "@/api/config";
import { getStore } from '@/utils/utils';
import {areaData} from '@/utils/data';
export default{
    props:{
        ModuleName: {
			type: String,
			default: ''
		},
    },
    data(){
        return {
            newstypedata:[],
            newstypeactive:0,
            newsdata:[],
            newshotdata:[],
            ProvinceName:'',
            pagetxt:'未找到资讯信息！'
        }
    },
    mounted(){
        this.GetNewsCategoryList();
        this.GetNewsInfoHotList();
    },
    methods:{
        newstypebtn(id){
            this.newstypeactive=areaData[id];
            this.GetNewsInfoList();
        },
        //获取新闻类别
        GetNewsCategoryList(){
            API.Web.GetNewsCategoryList().then(res => {
                if(res.Data.length>0){
                    this.newstypedata = res.Data;
                    this.newstypeactive=res.Data[0].Id;
                    this.GetNewsInfoList();
                }
            });
        },
        //获取新闻资讯
        GetNewsInfoList(){
            let provinceId = null;
            let aear = getStore('OrgProvince');
            if(aear!=undefined&&aear!=null&&aear!=undefined&&aear!=''){
                provinceId = JSON.parse(aear).ProvinceId;
                this.ProvinceName = JSON.parse(aear).ProvinceName;
            }
            let par = {};
            if(this.newstypeactive!==0){
                par = {pageIndex:1,pageSize:4,newsType:this.newstypeactive};
            }
            else{
                par = {pageIndex:1,pageSize:4,ExamProvinceId:provinceId};
            }
            API.Web.GetNewsInfoList(par).then(res => {
                this.newsdata = res.Data.Data;
            });
        },
        //获取热门新闻
        GetNewsInfoHotList(){
            API.Web.GetNewsInfoList({pageIndex:1,pageSize:12,isHot:true}).then(res => {
                this.newshotdata = res.Data.Data;
            });
        }
    }
}
</script>
<style lang="less" scoped>
.newsbox{
    max-width: 1420px;
    margin: auto;
    .newscon{
        margin: 20px 20px 0;
        border: 1px solid #eee;
        box-shadow: 0px 0px 3px 1px #ebeaea;
        .newshead{
            height: 50px;
            line-height: 50px;
            background-color: #f2f6fa;
            border-bottom: 1px solid #ebebeb;
            user-select: none;
            .txt{
                float: left;
                i{
                    float: left;
                    margin: 14px 15px 0px 20px;
                    width: 6px;
                    height: 19px;
                    background-color: rgb(var(--themecolor));
                }
                span{
                    font-size: 16px;
                    color: #555;
                    font-weight: bold;
                }
            }
            .ntab{
                float: left;
                height: 100%;
                margin-left: 20px;
                .ta{
                    height: calc(100% - 3px);
                    float: left;
                    color: #555;
                    font-size: 16px;
                    line-height: 50px;
                    padding: 0px 10px;
                    margin: 0px 2px;
                }
                .ta.active{
                    border-bottom: 3px solid rgba(var(--themecolor),0.5);
                }
                .ta:hover{
                    cursor: pointer;
                    border-bottom: 3px solid rgba(var(--themecolor),0.3);
                }
            }
            .more{
                float: right;
                width: 54px;
                height: 100%;
                line-height: 50px;
                color: #666;
                font-size: 12px;
                text-align: center;
                margin-right: 17px;
                i{
                    display: block;
                    transform: rotate(90deg);
                    -webkit-transform: rotate(270deg);
                    float: right;
                    color: #888;
                    font-size: 14px;
                }
            }
            .more:hover{
                cursor: pointer;
                color: rgb(var(--themecolor));
            }
        }
        .newslist{
            width: calc(100% - 390px);
            float: left;
            padding-top: 10px;
            max-height: 620px;
            overflow: hidden;
            .pages{
                width: 100%;
                min-height: 50px;
                line-height: 50px;
                color: #666;
                font-size: 16px;
                text-align: center;
                letter-spacing: 1px;
                margin-bottom: 10px;
                user-select: none;
                .iconfont{
                    font-size: 50px;
                    display: block;
                    margin-top: 40px;
                    color: #9b9b9b;
                }
            }
            .abox{
                height: 141px;
                margin: 0px 0px 10px 10px;
                overflow: hidden;
                background-color: #fff;
                border: 1px solid #ebebeb;
                border-radius: 5px;
                .tbox{
                    float: left;
                    width: calc(100% - 250px);
                    .tit{
                        font-size: 18px;
                        color: #333;
                        letter-spacing: 1px;
                        margin: 12px 20px 0px;
                        line-height: 30px;
                        height: 86px;
                        overflow: hidden;
                        transition: color 0.3s;
                        -webkit-transition: color 0.3s;
                    }
                    .mbox{
                        height: 30px;
                        margin-left: 20px;
                        margin-top: 10px;
                        i{
                            font-size: 14px;
                            color: #666;
                            margin-right: 5px;
                        }
                        span{
                            color: #888;
                            font-size: 12px;
                            margin-right: 20px;
                        }
                    }
                }
                .nimg{
                    float: right;
                    width: 208px;
                    height: 100px;
                    border-radius: 5px;
                    overflow: hidden;
                    margin: 20px;
                    .img{
                        width: 100%;
                        height: 100%;
                        transition: all 0.3s;
                        -webkit-transition: all 0.3s;
                    }
                }
            }
            .abox:hover{
                cursor: pointer;
            }
            .abox:hover .tbox .tit{
                color: rgb(var(--themecolor));
            }
            .abox:hover .nimg .img{
                transform:scale(1.08);
                -webkit-transform:scale(1.08);
            }
        }
        .newshot{
            float: right;
            width: 358px;
            border: 1px solid #ebebeb;
            margin: 10px 10px 0px;
            background-color: #fff;
            overflow: hidden;
            border-radius: 5px;
            padding-bottom: 20px;
            .rtit{
                border-bottom: 1px solid #ebebeb;
                height: 40px;
                line-height: 40px;
                i{
                    float: left;
                    margin: 11px 15px 0px 20px;
                    width: 6px;
                    height: 19px;
                    background-color: rgb(var(--themecolor));
                }
                span{
                    font-size: 16px;
                    color: #555;
                    font-weight: bold;
                }
            }
            .rlist{
                padding-top: 10px;
                .ra{
                    margin-left: 14px;
                    height: 44px;
                    line-height: 44px;
                    .ico{
                        float: left;
                        width: 30px;
                        height: 100%;
                        position: relative;
                        margin-right: 12px;
                        i{
                            font-size: 30px;
                            color: rgb(var(--themecolor));
                        }
                        span{
                            font-size: 14px;
                            position: absolute;
                            top: 0px;
                            left: 11px;
                            color: #666;
                        }
                    }
                    .txt{
                        color: #555;
                        font-size: 16px;
                        float: left;
                        width: calc(100% - 56px);
                        transition: color 0.3s;
                        -webkit-transition: color 0.3s;
                    }
                }
                .ra:hover{
                    cursor: pointer;
                }
                .ra:hover .txt{
                    color: rgb(var(--themecolor));
                }
                .ra:nth-child(-n+3) .ico span{
                    color: #fff;
                }
                .ra:nth-child(1) .ico i{
                    color: #eb312d;
                }
                .ra:nth-child(2) .ico i{
                    color: #fe7002;
                }
                .ra:nth-child(3) .ico i{
                    color: #f7aa4c;
                }
            }
        }
    }
}
</style>